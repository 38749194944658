<template>
  <div class="pages-content-scroll">
    <el-backtop
      :visibility-height="visibleHeight"
      :right="rightPos"
      :bottom="bottomPos"
    >
      <!-- <i class="iconfont icon-huidaodingbu"></i> -->
      <img src="../assets/icon_top.svg" alt="" />
      <p>回到顶部</p>
    </el-backtop>
    <slot></slot>
  </div>
</template>
<script>
export default {
  name: "Backtop",
  data() {
    return {
      visibleHeight: 200,
      rightPos: 8,
      bottomPos: 150,
    };
  },
};
</script>
<style scoped>
.pages-content-scroll {
  /* position: absolute; */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow-x: hidden;
}
.el-backtop {
  width: 90px;
  height: 90px;
  border-radius: 5px;
  background: rgba(201, 43, 47, 0.9);
  color: #fff;
  flex-direction: column;
  font-size: 14px;
}
.el-backtop:hover {
  background: rgba(201, 43, 47, 0.9);
}
img {
  width: 30px;
  height: 40px;
}
p {
  line-height: 18px;
}
.iconfont {
  font-size: 30px;
  color: #fff;
}
</style>