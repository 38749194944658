import Vue from 'vue'
import VueRouter from 'vue-router'
import Hotel from '../views/Hotel.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/hotel',
    name: 'Hotel',
    component: Hotel,
    meta: {
      title: '酒店预订',
  }

  },
  {
    path: '/hotelDetail',
    name: 'HotelDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/hotelDetail.vue'),
    meta: {
      title: '酒店预订',
  }
  },
  {
    path: '/shop',
    name: 'Shop',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/shop.vue'),
    meta: {
      title: '商品抢购',
  }
  },
  {
    path: '/shopDetail',
    name: 'ShopDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/shopDetail.vue'),
    meta: {
      title: '商品抢购',
  }
  },
  {
    path: '/guide',
    name: 'Guide',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/guide.vue'),
    meta: {
      title: '导游预约',
  }
  },
  {
    path: '/guideDetail',
    name: 'GuideDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/guideDetail.vue'),
    meta: {
      title: '导游预约',
  }
  },
  {
    path: '/ticket',
    name: 'Ticket',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ticket.vue'),
    meta: {
      title: '门票预订',
  }
  },
  {
    path: '/ticketDetail',
    name: 'TicketDetail',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ticketDetail.vue'),
    meta: {
      title: '门票预订',
  }
  },
  {
    path: '/ticketOrder',
    name: 'TicketOrder',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ticketOrder.vue'),
    meta: {
      title: '提交订单',
  }
  },
  {
    path: '/myHomePage',
    name: 'MyHomePage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/myHomePage.vue'),
    meta: {
      title: '我的主页',
  }
  },
  {
    path: '/shopCart',
    name: 'ShopCart',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/shopCart.vue'),
    meta: {
      title: '我的购物车',
  }
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
