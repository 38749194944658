<template>
  <div class="nav">
    <div>
      <ul>
        <li class="first-li">
          <a href="http://huangyao.cn/index.html">
            <img
              class="logo"
              src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-01/1630494943264.png?Expires=1945854934&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=sz%2B9w%2FCodWSWSn7jl0WRHjGbZJU%3D"
              alt=""
            />
          </a>
        </li>
        <li>
          <a href="http://huangyao.cn/index.html"> 首页 </a>
        </li>
        <li>
          <a
            href="http://huangyao.cn/articleTemplate.html?webPageId=90&menuId=155"
          >
            资讯
          </a>
          <div class="child-nav" style="z-index: 9999">
            <div>
              <div class="title">
                <h1>资讯</h1>
                <h2></h2>
              </div>
              <ul>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=90&amp;menuId=155"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-16/1634374458786.jpg?Expires=1949734451&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=jq%2FnqPx7a6JpRZOy3kFmjlIhiQQ%3D"
                      alt=""
                    />
                    <p>
                      <span>黄姚资讯</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=87&amp;menuId=154"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-12/1634006665348.jpg?Expires=1949366660&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=ZsvWJ89XBSVN42qG7GF7pVIcplk%3D"
                      alt=""
                    />
                    <p>
                      <span>创5A专题</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=86&amp;menuId=153"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-16/1634374456116.jpg?Expires=1949734451&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=%2BOGi2c%2FfemjJ3mSiz7a%2B1%2FhEQcE%3D"
                      alt=""
                    />
                    <p>
                      <span>景区公告</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a
            href="http://huangyao.cn/pageTemplate.html?webPageId=136&menuId=278"
          >
            印象
          </a>
          <div class="child-nav" style="z-index: 9999">
            <div>
              <div class="title">
                <h1>印象</h1>
                <h2></h2>
              </div>
              <ul>
                <li>
                  <a
                    href="http://huangyao.cn/pageTemplate.html?webPageId=136&amp;menuId=278"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-11/1633945844921.jpg?Expires=1949305843&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=Pfy2CpgQKr5PsFOMSJ3CLJEXZXs%3D"
                      alt=""
                    />
                    <p>
                      <span>黄姚荣誉</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=125&amp;menuId=257"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-20/1632145775375.jpg?Expires=1947505772&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=aPAGHYPGbSzqeYgkSFXuZkBmZcQ%3D"
                      alt=""
                    />
                    <p>
                      <span>红色文化</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=101&amp;menuId=129"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-06/1630920012093.jpg?Expires=1946280024&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=N71u43Pp%2FAkUL%2BhyAEReaCTZ9IQ%3D"
                      alt=""
                    />
                    <p>
                      <span>非遗系列</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=103&amp;menuId=128"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-08/1633680155361.jpg?Expires=1949040155&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=OqIYeYknaSIyEWmXCGnsx52qMTs%3D"
                      alt=""
                    />
                    <p>
                      <span>研学体验</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=102&amp;menuId=127"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-08/1631070898164.jpg?Expires=1947644244&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=P%2BsvXTg277ZevVNmwgf8zi4KSQc%3D"
                      alt=""
                    />
                    <p>
                      <span>民间传说</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=82&amp;menuId=130"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-01/1630490539121.jpg?Expires=1945850532&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=9j5f4pwp4J6NcCFSYAM83yBXHio%3D"
                      alt=""
                    />
                    <p>
                      <span>民俗节庆</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=127&amp;menuId=262"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-12/1634035960761.jpg?Expires=1949395959&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=MBvmd0kABNEBdoXsGIj9oOT98So%3D"
                      alt=""
                    />
                    <p>
                      <span>楹联牌匾</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=132&amp;menuId=267"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-14/1634181514292.jpg?Expires=1949541514&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=ZnyoYyeslKcH5mf2aPfY6h%2BvU64%3D"
                      alt=""
                    />
                    <p>
                      <span>宗祠文化</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=133&amp;menuId=271"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-12/1634011200910.png?Expires=1949371194&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=6NVYqgbyrD9X2Ty4QW%2BPNZEXCM0%3D"
                      alt=""
                    />
                    <p>
                      <span>文学作品</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=137&amp;menuId=275"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-08/1633684048440.jpg?Expires=1949044041&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=bt1EfvV72GDF7Ghr5X9cM%2FzpRBs%3D"
                      alt=""
                    />
                    <p>
                      <span>体育赛事</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a href="http://shop.huangyao.cn/Ticket"> 预订 </a>
          <div class="child-nav" style="z-index: 9999">
            <div>
              <div class="title">
                <h1>预订</h1>
                <h2></h2>
              </div>
              <ul>
                <!-- <li>
                  <a
                    href="http://shop.huangyao.cn/ticketDetail"
                    target="_blank"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-11/1633942271743.jpg?Expires=1949302270&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=dKKGlzNaHmU7ququBTHlnqwsTvk%3D"
                      alt=""
                    />
                    <p>
                      <span>门票预订</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li> -->
                <li>
                  <a href="http://shop.huangyao.cn/guideDetail" target="_blank">
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-12/1634005645230.jpg?Expires=1949365639&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=aRWf%2BCgUZZFLVWMq3CmKkuHWT%2BY%3D"
                      alt=""
                    />
                    <p>
                      <span>导游预约</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a href="http://shop.huangyao.cn/Hotel" target="_blank">
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-06/1630913661240.jpg?Expires=1946273687&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=jS2o4OlwbWYKbPOWzgZ15QMOreU%3D"
                      alt=""
                    />
                    <p>
                      <span>酒店预订</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a href="http://shop.huangyao.cn/Shop" target="_blank">
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-19/1634608895997.jpg?Expires=1949968895&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=hJyPEsbtFk9VGOrSgYBjEBZwFrM%3D"
                      alt=""
                    />
                    <p>
                      <span>产品抢购</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a
            href="http://huangyao.cn/pageTemplate.html?webPageId=139&menuId=266"
          >
            交通
          </a>
          <div class="child-nav" style="z-index: 9999">
            <div>
              <div class="title">
                <h1>交通</h1>
                <h2></h2>
              </div>
              <ul>
                <li>
                  <a
                    href="http://huangyao.cn/pageTemplate.html?webPageId=139&amp;menuId=266"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-23/1632387977292.png?Expires=1950157217&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=Jj%2FQJkBRz2fKR3hU9W1rKJpJLUY%3D"
                      alt=""
                    />
                    <p>
                      <span>交通指南</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a
            href="http://huangyao.cn/pageTemplate.html?webPageId=91&menuId=203"
          >
            游玩
          </a>
          <div class="child-nav" style="z-index: 9999">
            <div>
              <div class="title">
                <h1>游玩</h1>
                <h2></h2>
              </div>
              <ul>
                <li>
                  <a
                    href="http://huangyao.cn/pageTemplate.html?webPageId=91&menuId=203"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-27/1635344811793.jpg?Expires=1950704810&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=7AXyYHt8tFcl8zIM79eMjc9nSTQ%3D"
                      alt=""
                    />
                    <p>
                      <span>概况</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=96&amp;menuId=204"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-11/1633942271743.jpg?Expires=1949302270&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=dKKGlzNaHmU7ququBTHlnqwsTvk%3D"
                      alt=""
                    />
                    <p>
                      <span>景点</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/pageTemplate.html?webPageId=131&amp;menuId=205"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-27/1635344818811.jpg?Expires=1950704810&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=DnuodW3huLrUdGfMbL5SCOVtKRU%3D"
                      alt=""
                    />
                    <p>
                      <span>路线</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=112&amp;menuId=206"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-08/1631069276820.jpg?Expires=1946429277&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=v1KSQ9xAwjdRmggqSezOfonEgks%3D"
                      alt=""
                    />
                    <p>
                      <span>攻略</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a href="http://huangyao.cn/poi.html">
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-27/1635344815791.jpg?Expires=1950704810&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=5RFJDLu05aJoE01Rj2C4R95Hnak%3D"
                      alt=""
                    />
                    <p>
                      <span>导航导览</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a href="https://vr.huangyao.cn" target="_blank">
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-15/1631696999984.png?Expires=1947056996&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=5V2BVPsybRpijrkcbymriDYbM3Q%3D"
                      alt=""
                    />
                    <p>
                      <span>VR全景导览</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=116&amp;menuId=223"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-26/1632625744004.jpg?Expires=1947985738&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=vZIiNyG%2B%2B8tWweM4CwHLhOmAg4U%3D"
                      alt=""
                    />
                    <p>
                      <span>周边景区</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a
            href="http://huangyao.cn/articleTemplate.html?webPageId=92&menuId=210"
          >
            住宿
          </a>
          <div class="child-nav" style="z-index: 9999">
            <div>
              <div class="title">
                <h1>住宿</h1>
                <h2></h2>
              </div>
              <ul>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=92&amp;menuId=210"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-06/1630913298847.jpg?Expires=1946273329&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=XAEvnf%2BzhEH5Wup6oTEc6JfTfg4%3D"
                      alt=""
                    />
                    <p>
                      <span>酒店</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=93&amp;menuId=211"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-22/1632269208056.jpg?Expires=1947629202&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=Xjg0nVXoOj6tgISnISODW3nd2Qc%3D"
                      alt=""
                    />
                    <p>
                      <span>民宿</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a
            href="http://huangyao.cn/articleTemplate.html?webPageId=98&menuId=214"
          >
            美食
          </a>
          <div class="child-nav" style="z-index: 9999">
            <div>
              <div class="title">
                <h1>美食</h1>
                <h2></h2>
              </div>
              <ul>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=98&amp;menuId=214"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-08/1633703186437.jpg?Expires=1949063184&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=6Xcrz1yXWKUFjJTpaA51PLaGlDc%3D"
                      alt=""
                    />
                    <p>
                      <span>美滋美味</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=99&amp;menuId=215"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-23/1632386354593.png?Expires=1947746355&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=3cLDyL1iuvgZY09p6Bb8ulf5d20%3D"
                      alt=""
                    />
                    <p>
                      <span>特色餐飲</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a
            href="http://huangyao.cn/articleTemplate.html?webPageId=94&menuId=218"
          >
            购物
          </a>
          <div class="child-nav" style="z-index: 9999">
            <div>
              <div class="title">
                <h1>购物</h1>
                <h2></h2>
              </div>
              <ul>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=94&amp;menuId=218"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-06/1630915149086.jpg?Expires=1946275151&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=mpBScDGf0rbOFp0h36o%2BOcm4Bn8%3D"
                      alt=""
                    />
                    <p>
                      <span>特色购物</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=95&amp;menuId=217"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-26/1632626235891.jpg?Expires=1947986235&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=xG6A74tZ4ArYxOcJ8ZtlCIpQOQc%3D"
                      alt=""
                    />
                    <p>
                      <span>文创产品</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a
            href="http://huangyao.cn/articleTemplate.html?webPageId=115&menuId=221"
          >
            娱乐
          </a>
          <div class="child-nav" style="z-index: 9999">
            <div>
              <div class="title">
                <h1>娱乐</h1>
                <h2></h2>
              </div>
              <ul>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=115&amp;menuId=221"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-09-19/16303130806085151313.jpg?Expires=1947425502&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=hRrT7WU0pmeCPRP0p5mip8bMU%2Bg%3D"
                      alt=""
                    />
                    <p>
                      <span>文艺</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/articleTemplate.html?webPageId=135&amp;menuId=272"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-11/1633953389905.jpg?Expires=1949313386&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=hzpouQVJdqGLgDTnT2EIDY7cmdw%3D"
                      alt=""
                    />
                    <p>
                      <span>黄姚影视</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a
            href="http://huangyao.cn/pageTemplate.html?webPageId=111&menuId=142"
          >
            服务
          </a>
          <div class="child-nav" style="z-index: 9999">
            <div>
              <div class="title">
                <h1>服务</h1>
                <h2></h2>
              </div>
              <ul>
                <li>
                  <a
                    href="http://huangyao.cn/pageTemplate.html?webPageId=111&amp;menuId=142"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-14/1634183141563.jpg?Expires=1949543137&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=N43x7Z01Ntz6A7Ro6szZx15epQ0%3D"
                      alt=""
                    />
                    <p>
                      <span>游客服务</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a
                    href="http://huangyao.cn/pageTemplate.html?webPageId=128&amp;menuId=141"
                  >
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-14/1634183142407.jpg?Expires=1949543137&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=tmr1pi9Y2jTBZMiyEzvi0SpsS4Q%3D"
                      alt=""
                    />
                    <p>
                      <span>游客须知</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a href="http://huangyao.cn/faq.html?menuId=140">
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-14/1634183145892.jpg?Expires=1949543139&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=5%2FEEM9CfH1FH3J6Rd9UnKZucPOA%3D"
                      alt=""
                    />
                    <p>
                      <span>常见问题</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a href="http://huangyao.cn/lostFound.html?menuId=143">
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-14/1634183137149.jpg?Expires=1949543137&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=NSJ4duxxuuF%2BGLTiD6MnBLsI4N4%3D"
                      alt=""
                    />
                    <p>
                      <span>失物招领</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
                <li>
                  <a href="http://huangyao.cn/feedback.html?menuId=144">
                    <img
                      src="http://bibi-huangyao.oss-cn-shanghai.aliyuncs.com/2021-10-14/1634183143218.jpg?Expires=1949543137&amp;OSSAccessKeyId=LTAI4GJxALzftKu6WSo8n4oP&amp;Signature=IdAkpozTOvWJGEMaFLUpKXCgE6w%3D"
                      alt=""
                    />
                    <p>
                      <span>意见反馈</span>
                      <i class="el-icon-arrow-right"></i>
                    </p>
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </li>
        <li>
          <a href="http://huangyao.cn/search.html">
            <i class="el-icon-search" style="font-weight: bold"></i>
          </a>
        </li>
        <li style="padding: 0">
          <el-button type="text" @click="goCart"
            ><i class="iconfont icon-gouwuche"></i> 我的购物车</el-button
          >
        </li>
        <li style="padding: 0" class="scan">
          <el-button type="text"
            ><i class="iconfont icon-saoyisao"></i> 扫一扫</el-button
          >
          <div class="code">
            <div>
              <img src="../assets/code.jpg" alt="" />
              <p>扫码小程序下单</p>
            </div>
            <div>
              <img src="../assets/official-account-code.jpg" alt="" />
              <p>扫码关注公众号</p>
            </div>
          </div>
        </li>
        <li style="padding: 0" v-if="!token">
          <el-button @click="login">登录/注册</el-button>
        </li>
        
        <li style="padding: 0; line-height: 0" class="user-avatar" v-else>
          <el-dropdown
            trigger="hover"
            placement="bottom"
            @command="menuCommand"
          >
            <el-button round>
              <img
                :src="userInfo.avatarUrl"
                alt=""
                style="width: 100%; height: 100%; border-radius: 50%"
              />
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item command="homepage">我的主页</el-dropdown-item>
              <el-dropdown-item command="order">我的订单</el-dropdown-item>
              <el-dropdown-item command="address">收货地址</el-dropdown-item>
              <el-dropdown-item command="consignee"
                >游客信息</el-dropdown-item
              >
              <el-dropdown-item command="loginOut">退出</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </li>
      </ul>
    </div>
    <login ref="login"></login>
  </div>
</template>
<script>
import Login from "./login/login.vue";
import { getToken, setToken, removeToken } from "@/utils/auth";
import { mapState, mapMutations, mapAction } from "vuex";
export default {
  components: { Login },
  data() {
    return {};
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.userInfo,
      token:(state)=>state.token
    }),
  },
  methods: {
    menuCommand(command) {
      let rightShow;
      switch (command) {
        case "homepage":
          rightShow = "myPage";
          this.$router.push({ path: "/myHomePage" });
          break;
        case "order":
          console.log("order");
          rightShow = "ticket";
          this.$router.push({ path: "/myHomePage" });
          break;
        case "address":
          console.log("address");
          rightShow = "address";
          this.$router.push({ path: "/myHomePage" });
          break;
        case "consignee":
          console.log("consignee");
          rightShow = "consignee";
          this.$router.push({ path: "/myHomePage" });
          break;
        case "loginOut":
          this.logout();
          break;
      }
      sessionStorage.setItem("rightShow", rightShow);
    },
    goCart() {
      this.$router.push({
        path: "/shopCart",
      });
    },
    login() {
      // this.$refs.login.open()
      this.$store.commit("SET_SHOW", true);
    },
    logout() {
      this.$confirm("确定退出吗？", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        this.$store.dispatch("FedLogOut").then(() => {
          location.href = "/shop";
        });
      });
    },
  },
};
</script>
<style scoped lang='scss'>
.nav {
  height: 100px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  background: #fff;
  // margin-bottom: 20px;
  > div {
    padding: 0 20px;
    max-width: 1400px;
    min-width: 1200px;
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    margin: 0 auto;
  }
  ul {
    width: 100%;
    height: 100px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
    -webkit-justify-content: flex-start;
    -ms-flex-pack: start;
    justify-content: flex-start;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    .first-li {
      height: 100px;
      line-height: 100px;
      -webkit-box-flex: 1;
      -webkit-flex: 1;
      -ms-flex: 1;
      flex: 1;
      text-align: left;
    }
    li {
      color: #13253d;
      padding: 0 15px;
      font-size: 14px;
      cursor: pointer;
      line-height: 100px;
      text-align: center;
      &:hover {
        > a {
          color: red;
        }
        > .child-nav {
          display: flex;
        }
      }
      .child-nav {
        position: absolute;
        display: flex;
        left: 0;
        top: 100px;
        width: 100%;
        background-color: #f8f8f8;
        z-index: 1;
        display: none;
        > div {
          max-width: 1400px;
          min-width: 1200px;
          display: flex;
          min-height: 350px;
          margin: 50px auto 0 auto;
          .title {
            padding-left: 20px;
            min-width: 250px;
            h1 {
              font-size: 30px;
              color: #333333;
              font-weight: 400;
              line-height: 1;
              margin: 0;
            }
          }
          ul {
            width: 100%;
            margin: 0 auto;
            padding: 0;
            display: flex;
            flex-wrap: wrap;
            justify-content: flex-start;
            li {
              width: 200px;
              text-align: left;
              margin-left: 20px;
              padding: 0;
              cursor: pointer;
              img {
                width: 200px;
                height: 120px;
                border-radius: 5px;
                display: block;
              }
              p {
                margin: 17px 0;
                padding: 0;
                display: flex;
                justify-content: space-between;
                align-items: center;
                line-height: 1;
                font-size: 14px;
              }
            }
          }
        }
      }
      &:nth-last-child(1),
      &:nth-last-child(2) {
        ::v-deep .el-button {
          span {
            border-right: none;
          }
        }
      }
      &:nth-last-child(1) {
        .el-button {
          padding: 12px 0;
        }
      }
      ::v-deep .el-button {
        color: #13253d;

        span {
          padding: 0 15px;
          border-right: 1px solid;
        }
      }
      .iconfont {
        font-size: 14px;
      }
      .code {
        position: absolute;
        left: -135px;
        background: #fff;
        z-index: 1001;
        display: none;
        &::after {
          content: "";
          width: 1px;
          height: 130px;
          background: rgb(233, 233, 233);
          position: absolute;
          left: 180px;
          top: 30px;
        }
        img {
          width: 150px;
          height: 150px;
          display: block;
          padding: 15px;
        }
        p {
          text-align: center;
          padding-bottom: 5px;
          line-height: 20px;
        }
      }
    }
    .scan {
      position: relative;
      &:hover {
        .code {
          display: flex;
        }
      }
    }
    .user-avatar {
      ::v-deep .el-button {
        width: 30px;
        height: 30px;
        padding: 0 !important;
        span {
          padding: 0;
        }
      }
    }
  }
  .logo {
    width: 270px;
    height: 46px;
    margin-top: 27px;
  }
}
</style>