<template>
  <el-dialog
    title=""
    :visible.sync="loginShow"
    width="30%"
    center
    @close="loginClose"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
  >
    <iframe
      :src="codeUrl"
      frameborder="0"
      width="100%"
      height="400"
      id="preview"
    ></iframe>
    <!-- <el-button @click="login">模拟登录按钮</el-button> -->
  </el-dialog>
</template>
<script>
import { setToken } from "@/utils/auth";
export default {
  data() {
    return {
      codeUrl:""
    };
  },
  computed:{
    loginShow:{
      get(){
        return this.$store.state.loginShow
      },
      set(){}
    }
  },
  created(){
      // true为测试
      const debug=false
      let appid,url
      if(debug){
        appid='wx484b0e86dfa22e71'
        url='http://shop.huangyao.test.jekun.com'
      }else{
        appid='wx73057fcfc464c11b'
        url='http://shop.huangyao.cn'
      }
      this.codeUrl=`https://open.weixin.qq.com/connect/qrconnect?appid=${appid}&redirect_uri=${url}/shop&response_type=code&scope=snsapi_login&state=STATE#wechat_redirect`
  },
  methods: {
    loginClose() {
      this.$store.commit("SET_SHOW", false);
    },
    // login(){
      // setToken('6ec4bbb0-0c26-476b-b7e6-bbd5fb443ff4')
      // this.$store.commit('SET_TOKEN','6ec4bbb0-0c26-476b-b7e6-bbd5fb443ff4')
      // this.$store.commit('SET_SHOW',false);
    // }
  },
};
</script>