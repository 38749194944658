<template>
  <div class="hotel-content">
    <p class="crumbs">
      <a href="http://huangyao.cn/index.html">首页</a> > <span>酒店预订</span>
    </p>
    <section class="main-content">
      <ul class="tab-sort">
        <li class="sort-item active">智能排序</li>
        <li class="sort-item">好评优先</li>
        <li class="sort-item">人气优先</li>
        <li class="sort-item">价格最低</li>
        <li class="sort-item">价格最高</li>
      </ul>
    </section>
    <div class="hotel-main">
      <div class="hotel-list">
        <ul class="list-box">
          <li class="hotel-item" v-for="item in hotelList" :key="item.hotelId">
            <div class="left">
              <div class="hotel-img">
                <a @click="go(item.hotelId)">
                  <img :src="imgUrl + item.hotelCoverImage" alt="" />
                </a>
              </div>
              <div class="hotel-msg">
                <span class="hotel-name"
                  ><a @click="go(item.hotelId)">{{ item.hotelName }}</a></span
                >
                <p><i class="el-icon-location"></i>{{item.hotelAddress}}</p>
                <el-tag type="warning" size="mini">{{item.starRatingName}}</el-tag>
                <div class="hotel-facilities">
                  <p style="width:60px;flex:none;">酒店设施：</p>
                  <p class="hotel-facilities-list"><span v-for="items in item.hotelFacilityNames" :key="items">{{items}}</span></p>
                </div>
              </div>
            </div>
            <div class="right">
              <div class="hotel-info">
                <p>
                  <span>￥{{ item.minPrice }}</span
                  >起
                </p>
                <button @click="go(item.hotelId)">
                  立即预订
                  <div class="code">
                    <img
                      src="../assets/code/hotel-code.png"
                      alt=""
                    />
                    <p>扫码预订</p>
                  </div>
                </button>
              </div>
            </div>
          </li>
        </ul>
      </div>
      <div id="hotel-map"></div>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src

export default {
  name: "Hotel",
  data() {
    return {
      hotelList: "",
    };
  },
  created() {
    //window.document.title = "酒店预订";
    this.init();
  },
  mounted() {
    this.initMap();
    
  },
  methods: {
    go(id) {
      this.$router.push({ path: "/hotelDetail", query: { hotelId: id } });
    },
    init() {
      this.$axios
        .get(this.apiUrl + "/app/api/hotel/selectSysHotelList")
        .then((res) => {
          this.hotelList = res.rows.map(item=>{
            item.hotelFacilityNames=item.hotelFacilityNames.split(",")
            return item
          });
          // console.log(this.hotelList, "hotelList");
          // this.mapData()
        });
        
    },
    initMap() {
      var center = new TMap.LatLng(24.246103, 111.203767);
      //初始化地图24.246103, 111.203767
      var map = new TMap.Map("hotel-map", {
        rotation: 20, //设置地图旋转角度
        pitch: 30, //设置俯仰角度（0~45）
        zoom: 15, //设置地图缩放级别
        center: center, //设置地图中心点坐标
      });
      var marker = new TMap.MultiMarker({
        map: map,
        styles: {
          // 点标记样式
          myStyle: new TMap.MarkerStyle({
            width: 20, // 样式宽
            height: 30, // 样式高
            color: "#F4531F",
            size: 14,
            direction: "bottom",
            anchor: { x: 10, y: 30 }, // 描点位置
            // src: "https://mapapi.qq.com/web/lbs/javascriptGL/demo/img/markerDefault.png", // 标记路径
          }),
        },
        geometries: [
          // 点标记数据数组
          {
            styleId: "myStyle",
            // 标记位置(经度，纬度，高度)
            position: new TMap.LatLng(24.246103, 111.203767),
            content: "黄姚半岛酒店",
          },
          {
            styleId: "myStyle",
            position: new TMap.LatLng(24.24995, 111.20258),
            content: "黄姚酒壶山酒店",
          },
        ],
      });
      
    },
    // mapData(){
    //   console.log(this.hotelList, "hotelList");
    //   let markerList=this.hotelList.map((item,index)=>{
    //     return {styleId:'myStyle',content:item.hotelName,position: new TMap.LatLng(item.latitude, item.longitude)}
    //   })
    //   console.log(markerList,'看一下是啥')
    // }
  },
};
</script>
<style scoped lang='scss'>
.hotel-content {
  min-width: 1160px;
  width: 1000px;
  // height: 60px;
  margin: 0 auto;
  .crumbs {
    font-size: 14px;
    font-weight: 400;
    color: rgba(29, 29, 29, 0.7);
    line-height: 30px;
    a {
      color: rgba(29, 29, 29, 0.7);
      &:hover {
        color: #c92b2f;
      }
    }
  }
  .main-content {
    background: #fff;
    .tab-sort {
      width: 100%;
      display: flex;
      padding: 16px 0;
      .sort-item {
        padding: 0 20px;
        font-size: 15px;
        line-height: 18px;
        cursor: pointer;
        position: relative;
        &::after {
          content: "";
          width: 2px;
          height: 1em;
          background: #898989;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: -1px;
        }
      }
    }
  }
  .hotel-main {
    display: inline-flex;
    padding-top: 20px;
    .hotel-list {
      .list-box {
        padding-right: 20px;
        .hotel-item {
          width: 750px;
          height: 150px;
          background: #fff;
          box-shadow: 0px 0px 12px rgba(0, 0, 0, 0.1);
          border-radius: 5px;
          padding: 20px;
          margin-bottom: 20px;
          display: inline-flex;
          justify-content: space-between;
          .left {
            display: inline-flex;
            padding-right: 10px;
            // border-right: 0.5px solid #f5f5f5;
            width: 600px;
            .hotel-img {
              width: 180px;
              height: 150px;
              margin-right: 15px;
              cursor: pointer;
              img {
                width: 180px;
                height: 150px;
                border-radius: 3px;
              }
            }
            .hotel-msg {
              .hotel-name {
                display: block;
                font-weight: bold;
                font-size: 20px;
                margin-bottom: 8px;
                cursor: pointer;
                a {
                  color: #2c3150;
                  &:hover {
                    color: #f3581f;
                    font-weight: bold;
                  }
                }
              }
              p {
                font-size: 12px;
                color: #b2b3b5;
                margin: 8px 0;
              }
              .hotel-facilities{
                display: flex;
                .hotel-facilities-list{
                  display: flex;
                  flex-wrap: wrap;
                  overflow: hidden;
                  -webkit-line-clamp: 3; 
                  -webkit-box-orient: vertical; 
                  display: -webkit-box;
                  span{
                    padding-right: 5px;
                  }
                }
              }
            }
          }
          .right {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            .hotel-info {
              p {
                font-size: 14px;
                text-align: right;
                span {
                  font-size: 20px;
                  font-weight: bold;
                  color: #f49a49;
                }
              }
              button {
                width: 100px;
                height: 35px;
                color: #fff;
                font-size: 16px;
                line-height: 30px;
                background: #f4531f;
                border-radius: 5px;
                border: none;
                cursor: pointer;
                position: relative;
                .code {
                  width: 150px;
                  height: 175px;
                  background: #fff;
                  position: absolute;
                  right: -150px;
                  top: -120px;
                  display: none;
                  z-index: 9999;
                  img{
                    display: block;
                    width: 150px;
                  }
                  p{
                    text-align: center;
                    color: #000;
                    line-height: 25px;
                  }
                }
                &:hover {
                  .code {
                    display: block;
                  }
                }
              }
            }
          }
        }
      }
    }
    #hotel-map {
      height: 350px;
      width: 100%;
      // ::v-deep.tmap-scale-control{
      //   display: none;
      // }
      // ::v-deep.logo-text{
      //   display: none;
      // }
    }
  }
}
</style>
