import Vue from 'vue'
import Vuex from 'vuex'
import { getToken, setToken, removeToken } from '@/utils/auth'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loginShow: false,
    token:getToken(),
    userInfo:JSON.parse(localStorage.getItem('userInfo'))
  },
  mutations: {
    SET_SHOW: (state, isShow) => {
      state.loginShow = isShow
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_USERINFO:(state,userInfo)=>{
      state.userInfo = userInfo
      localStorage.setItem('userInfo',JSON.stringify(userInfo))
    }
  },
  actions: {
    // 登录
    Login({ commit }, userInfo) {},
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        logout(state.token).then(() => {
          commit('SET_TOKEN', '')
          commit('SET_USERINFO', '')
          removeToken()
          resolve()
        }).catch(error => {
          reject(error)
        })
      })
    },
    // 登出
    FedLogOut({ commit }) {
      return new Promise(resolve => {
        commit('SET_TOKEN', '')
        commit('SET_USERINFO', '')
        removeToken()
        resolve()
      })
    }
  },
})
