import router from './router'
import store from './store'
import { getToken } from '@/utils/auth'
//免登陆白名单
const whiteList = ['/guide', '/guideDetail', '/hotel', '/hotelDetail', '/shop', '/shopDetail', '/ticket', '/ticketDetail']

router.beforeEach((to, from, next) => {
  if (to.meta.title) {//判断是否有标题
    document.title = to.meta.title
  }
  if (getToken()) {
    /* has token*/
    store.commit('SET_SHOW',false);
    next()
  } else {
    // 没有token
    if (whiteList.indexOf(to.path) !== -1) {
      // 在免登录白名单，直接进入
      next()
    } else {
      // next(`/login?redirect=${to.fullPath}`) // 否则全部重定向到登录页
      // next() // 直接进入（开发）
      store.commit('SET_SHOW',true);//否则打开扫码登录窗
    }
  }
})

router.afterEach(() => {
  
})
