import { getToken, setToken, removeToken } from '@/utils/auth'

let token = getToken()
// let apiUrl = 'https://mall-api.huangyao.cn'

// let apiUrl = 'http://192.168.1.193:7777'
let authorization='Bearer '+ token
// let authorization='Bearer '+ '6ec4bbb0-0c26-476b-b7e6-bbd5fb443ff4'

let apiUrl,imgUrl
const debug = false;
if(debug){
    apiUrl = 'https://sbs-app-api.test.jekun.com'
    imgUrl ='http://bibi-sbs.oss-cn-shanghai.aliyuncs.com'
}else{
    apiUrl = 'https://mall-api.huangyao.cn'
    imgUrl ='http://bibi-sbs.oss-cn-shanghai.aliyuncs.com'
}
export{
    apiUrl,
    authorization,
    imgUrl
}