<template>
  <div id="app">
    <back-top>
      <Nav></Nav>
      <router-view />
      <Footer></Footer>
    </back-top>
  </div>
</template>
<script>
import Nav from "./components/nav.vue";
import Footer from "./components/footer.vue";
export default {
  components: { Nav, Footer },
  created() {
    // true 测试
    const debug = false;
    let url = window.location.href;
    let pageName=url.substring(url.lastIndexOf('/')+1)
    if (debug) {
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        if (pageName=="shop") {
          window.location.href =
            "http://h5.shop.huangyao.test.jekun.com/pages/h5/merchandiseList";
        } else if (pageName=="hotel") {
          window.location.href = "http://h5.shop.huangyao.test.jekun.com/pages/h5/hotelList";
        } else if (pageName=="ticketDetail") {
          window.location.href =
            "http://h5.shop.huangyao.test.jekun.com/pages/h5/ticket?ticketType=1&ticketCategoryId=1&scenicId=1";
        } else if (pageName=="guideDetail") {
          window.location.href =
            "http://h5.shop.huangyao.test.jekun.com/pages/h5/ticket?ticketType=2&ticketCategoryId=2&scenicId=2";
        }
      }
    }else{
      if (/Android|webOS|iPhone|iPod|BlackBerry/i.test(navigator.userAgent)) {
        if (pageName=="shop") {
          window.location.href =
            "http://m.huangyao.cn/pages/h5/merchandiseList";
        } else if (pageName=="hotel") {
          window.location.href = "http://m.huangyao.cn/pages/h5/hotelList";
        } else if (pageName=="ticketDetail") {
          window.location.href =
            "http://m.huangyao.cn/pages/h5/ticket?ticketType=1&ticketCategoryId=1&scenicId=1";
        } else if (pageName=="guideDetail") {
          window.location.href =
            "http://m.huangyao.cn/pages/h5/ticket?ticketType=2&ticketCategoryId=2&scenicId=2";
        }
      }
    }
  },
};
</script>
<style lang="scss">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  background: rgb(249, 249, 249);
  height: 100%;
  width: 100%;
}
</style>
