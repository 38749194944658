import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Nav from './components/nav.vue'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import './assets/reset.css'
import {apiUrl,authorization,imgUrl} from './config/index'
import axios from './axios'
import BaiduMap from 'vue-baidu-map'
import './permission' // 路由守卫
import './assets/iconfont/iconfont.css'//全局引入阿里矢量图标css
import BackTop from './components/backTop.vue'
Vue.component('backTop', BackTop)
Vue.use(BaiduMap,{
  ak:'4sYF1d9bP1jEV2DqYHg5hPkePuZUpfuP'
})
import {VueJsonp} from "vue-jsonp"
Vue.use(VueJsonp)

Vue.config.productionTip = false
Vue.use(ElementUI);
// axios.defaults.headers.common['Authorization']=authorization
Vue.prototype.apiUrl = apiUrl
Vue.prototype.imgUrl = imgUrl
// Vue.prototype.token =token
Vue.prototype.$axios = axios
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
